import { FlyOver } from 'components/Layout';
import ProductPreview from 'components/ProductPreview/ProductPreview';
import { useMutateAddWishlist } from 'features/wishlist/mutations';
import useRouter from 'hooks/useRouter';
import { pathnames } from 'i18n/pathnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useShowWishListOverlay,
  useShowWishlistSuccessAction,
  useWishlistActions,
  useWishlistProduct,
} from 'store/wishlist';
import { Button, Icon, Link, Text } from 'ui';
import { getLocalizedRoute } from 'utils/localizedRouteUtil';
import WishlistFormModal from '../WishlistModal/WishlistFormModal';
import WishListOverview from '../WishlistOverview/WishlistOverview';

const WishlistFlyOver = () => {
  const { formatMessage } = useIntl();
  const { locale, pathname } = useRouter();
  const [showAddWishlistModal, setShowAddWishlistModal] = useState(false);

  const showWishListOverlay = useShowWishListOverlay();
  const wishListProduct = useWishlistProduct();

  const showSuccess = useShowWishlistSuccessAction();
  const { setShowWishlistSuccessMessage, toggleWishListOverlay } = useWishlistActions();

  const { mutateAsync: addWishlist } = useMutateAddWishlist();

  const handleAddWishlist = async (values?: Record<string, string>) => {
    await addWishlist({ ...values });
  };

  const closeWishListOverlay = () => {
    toggleWishListOverlay(false);
    setShowWishlistSuccessMessage(false);
  };

  const wishlistsLink = getLocalizedRoute(pathnames.WISHLIST, locale, {
    query: pathname === pathnames.SEARCH ? { fromSearch: true } : undefined,
  });

  return (
    <FlyOver onOpenChange={closeWishListOverlay} side="right" open={showWishListOverlay} withCloseIcon={false}>
      <WishlistFormModal
        side="right"
        buttonCopy={formatMessage({
          id: 'wish_list_add_form_submit_btn',
        })}
        callback={handleAddWishlist}
        closeModal={() => setShowAddWishlistModal(false)}
        show={showAddWishlistModal}
        title={formatMessage({
          id: 'wish_list_add_wish_list_title',
        })}
      />

      <div className="flex h-full flex-col">
        {/* Header */}
        <div className="-mt-5 flex flex-col justify-center bg-accent-20 px-4 py-8">
          <Text type="h3" className="mb-4 text-center">
            {formatMessage({ id: 'wish_list_overlay_title' })}
          </Text>

          <div className="m-auto flex max-w-3/4 flex-col items-center gap-6">
            {showSuccess && (
              <Text className="flex items-center gap-4 border border-accent-40 bg-white px-6 py-4 text-success">
                <Icon icon={{ name: 'check', styling: 'far' }} className="text-success" />
                {formatMessage({
                  id: 'general_modal_successful_adding_of_product',
                })}
              </Text>
            )}

            <ProductPreview product={wishListProduct} />
            {wishlistsLink && (
              <Link className="underline" href={wishlistsLink}>
                {formatMessage({
                  id: 'wish_list_overlay_wish_list_link',
                })}
              </Link>
            )}
          </div>
        </div>

        {/* Content */}
        <WishListOverview className="px-8 pb-[120px]" openAddWishlistModal={() => setShowAddWishlistModal(true)} />

        {/* Footer */}
        <div className="sticky bottom-[-20px] flex w-full justify-center bg-white p-8">
          <Button onClick={closeWishListOverlay}>
            {formatMessage({
              id: 'general_checkout_cart_empty_cart_continue_shopping',
            })}
          </Button>
        </div>
      </div>
    </FlyOver>
  );
};

export default WishlistFlyOver;
